import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"
import { RulesContent } from "../components/rules/RulesContent"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="利用規約,トナリノ,デザイン,デザイン相談"
      description="トナリノの利用規約をご確認いただけます。"
      ogTitle="利用規約"
      ogDescription="トナリノの利用規約ページです"
      ogUrl="https://tonarino.co/privacy"
    />
    <PageTitleBox title="利用規約" />
    <section id="rulesContent">
      <RulesContent />
    </section>
  </Layout>
)

export default IndexPage
