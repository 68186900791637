import * as React from "react"

export const RulesContent = () => {
  return (
    <>
      <div className="container pageBodyBox">
        <section id="description">
          <p>トナリノ利用規約（以下「本規約」といいます）は、デザインに関する悩みを伺い、一緒に伴走してゆくサービス「トナリノ」（以下「本サービス」といいます）を企画運営するnao（以下「運営者」といいます）が、本サービスの利用条件を定めるものです。</p>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第１条（原則）</h2>
          <p>本サービスは、本サービスを利用する方（以下「利用者」といいます）が自律的に行う業務を支援するためのサービスであり、デザイン全般の制作委託サービスではありません。したがって、運営者にデザイン全般の制作委託を希望される方は、本サービスとは別に、運営者が提示する見積りに従い、運営者との間で、デザイン制作に関する契約を締結するものとします。</p>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第２条（本規約）</h2>
          <ol>
            <li>本規約は、本サービスの利用に関して、利用者と運営者との間の一切の関係に適用されるものとします。</li>
            <li>運営者の定めるプライバシーポリシー（以下「プライバシーポリシー」といいます）及び運営者が本サービスを案内するWebサイト（以下「本サイト」といいます）において掲載する注意事項等は、本規約の一部を構成するものとします。</li>
            <li>本規約における用語は以下のとおりとします。
              <ol type="i">
                <li>デザイン業務：主にWebサイト、アプリケーション、UIデザイン、印刷物など、その他の制作物の全般のデザイン等を行う業務をいいます。</li>
                <li>アドバイザリーサービス：本サービスのうち、利用者の行うデザイン業務に対して運営者が助言、支援、提案等を行うサービスをいいます。</li>
                <li>提供時間：アドバイザリーサービスを提供するために運営者が定めた時間単位をいいます。利用者は、運営者の営業時間内に限って、提供時間を設定することができます。</li>
                <li>アセット制作サービス：本サービスのうち、運営者が利用者のデザイン業務に必要な一部のアセットを制作するサービスをいいます。</li>
                <li>制作アセット：アセット制作サービスにおいて、運営者が制作するアセットをいいます。</li>
                <li>対象制作物：制作アセットが使用される対象となる利用者のデザイン制作物をいいます。</li>
                <li>指定システム：運営者指定のサービス事業者が提供する予約、決済、管理等を行うためのシステムをいいます。</li>
                <li>利用料金：アドバイザリーサービス又はアセット制作サービスの対価として、運営者が定め、本サイトに表示した料金をいいます。</li>
              </ol>
            </li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第３条（利用申請）</h2>
          <ol>
            <li>利用者は、本規約及びプライバシーポリシーに同意のうえ、指定システムを通じて、利用料金を決済すると共に、本サービスの利用を申請するものとします。なお、利用者は、申請に先立って、指定システムのメッセージ機能によって提供時間を運営者と調整するものとします。</li>
            <li>前項の申請について、運営者が承諾したときに、本サービスの利用者としての資格（以下「利用資格」といいます）が付与され、提供時間又は運営者の指定した時に、利用者は、本サービスを利用することができるものとします。</li>
            <li>運営者は、前項に関わらず、利用者が以下に該当すると判断した場合、利用資格を付与しないことがあります。この場合、運営者は利用資格を付与しなかった理由を開示する義務を負わないものとします。
              <ol type="i">
                <li>過去に本規約に違反した事実がある場合</li>
                <li>申請内容に虚偽が認められた場合</li>
                <li>その他、利用者としての適性を欠くと運営者が判断した場合</li>
              </ol>
            </li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第４条 (変更手続き)</h2>
          <p>利用者は、指定システムへの登録情報に変更がある場合、遅滞なく、運営者の定めた変更手続きをしなければならないものとします。</p>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第５条（アドバイザリーサービス）</h2>
          <ol>
            <li>運営者は、アドバイザリーサービスについて、運営者の知見と経験に基づいて概ね以下の内容を限度として行うものとし、利用者の期待や要望に無制限に応じる義務は負わないものとします。
              <p>ロゴ、名刺、封筒、チラシ、広告、装丁、画像加工、切り抜き、二次創作、アメニティグッズ、Webデザイン、UIデザイン等</p>
            </li>
            <li>
              利用者は、アドバイザリーサービスの提供を受けるにあたって、運営者が定めた以下のプランに従うものとします。運営者は、かかるプランの内容を随時変更又は終了することができるものとします。
              <ol type="i">
                <li>相談プラン：提供時間内に限って、利用者のデザイン業務に関する利用者の相談に応じるプラン</li>
                <li>デザイン改善プラン：デザインに関する利用者の状況や課題等をヒアリングしたうえで、1回限り、運営者からの提案を行うプラン</li>
                <li>その他、運営者が随時追加するプラン</li>
              </ol>
            </li>
            <li>
              利用者は、提供時間に限ってアドバイザリーサービスの提供を受けられるものとし、提供時間を超えてアドバイザリーサービスの提供を受けようとする場合、当該利用者は、第３条第１項に基づき、改めて利用申請を行うものとします。
            </li>
            <li>利用者は、アドバイザリーサービスをキャンセルする場合、以下に従い、所定のキャンセル料を支払うものとします。
              <ol type="i">
                <li>予約前々日までのキャンセル：無料</li>
                <li>予約前日以降のキャンセル：利用料金の100％（キャンセル連絡の有無又はキャンセル連絡の時間を問わない）</li>
              </ol>
            </li>
            <li>アドバイザリーサービスの性質は、委任契約であり、仕事の完成義務を負う請負契約や、雇用主の指揮命令に基づく雇用契約ではありません。このため、運営者は、アドバイザリーサービスによって、利用者のデザイン業務の課題解決等について、いかなる保証を負うものでも義務を負うものでもありません。このため、利用者は、自らの責任に基づき、アドバイザリーサービスを利用するものとします。</li>
            <li>運営者は、アドバイザリーサービスを通じて、運営者の知見と経験に基づいた情報（以下「提供情報」といいます）を利用者に提供することがあります。運営者は、提供情報の内容が有益であるように努めますが、運営者の責任において内容を保証するものではないため、利用者は、あくまでも自らの責任において提供情報を利用するものとします。</li>
            <li>利用者は、提供情報について、原則として守秘義務を負うものとし、また、運営者の許可した目的の範囲を超えて利用してはならないものとします。また、提供情報の著作権は、運営者に帰属するものとします。このため、利用者は、運営者に無断で、提供情報を第三者に開示し、又はSNS等に掲示してはならないものとします。</li>
            <li>
              運営者は、運営者指定のGooge Meet等のオンラインコミュニケーションサービスを通じて、アドバイザリーサービスを提供するものとし、電話による提供は一切行わないものとします。このため、利用者は、アドバイザリーサービスを受ける際に発生する自らの機器及びオンラインコミュニケーションに必要なアプリケーション（以下「アプリ」といいます）の準備等に必要な費用を負担するものとし、以下を承諾するものとします。
              <ol type="i">
                <li>アプリの提供会社の定めた規律を遵守すること</li>
                <li>サービス開始までに必要なアプリをインストールすること</li>
                <li>運営者は、アプリの不具合（通信状態を含みます）等について一切責任を負わないこと</li>
                <li>運営者は、アプリ及び通信に関するサポートは一切行わないこと</li>
              </ol>
            </li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第６条（アセット制作サービス）</h2>
          <ol>
            <li>
              運営者は、対象制作物に必要な範囲で、以下に従い、アセット制作サービスを提供するものとします。
              <ol type="i">
                <li>制作期間：利用者の要望に応じて都度設定</li>
                <li>提案・修正回数：利用料金の範囲内で、提案１回、修正２回まで</li>
                <li>納品形式：psd・ai・pngのいずれかで、利用者に合致するものを運営者より提案</li>
                <li>その他、アセット制作サービスの利用料金に鑑み、原則として運営者の示す制約に従うこと</li>
              </ol>
            </li>
            <li>制作アセットに関する著作権その他の知的財産権は、運営者に帰属するものとし、運営者は、利用者に対象制作物に必要な範囲で、利用者に対し、当該知的財産権の使用を許諾するものとし、使用許諾の対価は、次条に定める利用料金に含まれるものとします。</li>
            <li>利用者は、運営者がアセット制作サービスに着手した以降においては、利用者の都合によるキャンセルを行うことはできないものとし、運営者は、いったん収受した利用料金について、利用者に返還する義務を負わないものとします。</li>
            <li>利用者が対象制作物自体の制作を運営者に委託することを希望する場合、運営者が提示する見積りに従い、利用者と運営者との間で、別途デザイン制作に関する契約を締結するものとします。</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第７条（利用料金の支払い）</h2>
          <ol>
            <li>利用者は、運営者の定めた利用料金について、本サービスへの申込の都度、事前に、一括にて、運営者指定の決済事業者を通じて支払うものとします。ただし、運営者が毎月定額料金のサブスクリプション方式を導入し、利用者がこれを選択した場合、当該利用者は、運営者が請求する翌月分の利用料金について、毎月支払うものとします。</li>
            <li>利用料金の支払方法については、指定システムを通じたクレジット決済による方法、又は、運営者指定の決済事業者を通じて運営者の金融機関口座へ振り込む方法とします。後者の場合の振込手数料は、利用者の負担とします。</li>
            <li>運営者は、キャンセル料について、利用料金の返還額と相殺することによって支払を受けることができるものとします。</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第８条 (本サービスの停止、中断)</h2>
          <ol>
            <li>運営者は、利用者が本規約の一つにでも違反するおそれを認めた場合、予告なく当該利用者に対する本サービスの提供を停止すると共に、利用資格を無期限に喪失させることができるものとします。</li>
            <li>運営者は、以下の各号いずれかに該当する場合、予告なく、本サービスの全部又は一部の提供を中断することができるものとします。
              <ol type="i">
                <li>運営者の指定するサービスやシステムに障害が発生した場合</li>
                <li>運営者の指定するサービスやシステムの提供者が緊急でコンピュータシステムの点検、保守を行う場合</li>
                <li>停電、サーバー故障等が発生した場合</li>
                <li>コンピュータ、通信回線等に不良がある場合</li>
                <li>地震、落雷、火災等の不可抗力による場合</li>
                <li>運営者の休業による場合</li>
                <li>その他運営者が必要と判断した場合</li>
              </ol>
            </li>
            <li>運営者は、前二項によって利用者に生じた損害について、一切の責任を負わないものとします。また、運営者は第１項によって運営者に生じた損害について、利用者及び利用者であった者に対して請求することができるものとします。</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第９条 (禁止事項について) </h2>
          <p>利用者は、本サービスの利用にあたり、以下の各号に該当する行為をしてはならないものとします。</p>
          <ol>
            <li>運営者が提示した追加料金を支払わない行為</li>
            <li>運営者が提供した非公開な提供情報を外部に漏洩する行為</li>
            <li>アドバイザリーサービスのの音声、画像、動画等のデータについて、運営者の許可なく第三者に開示し又はこれらをインターネット上（ホームページ、ブログ、Facebook、Instagram、LINE、Twitter等）にアップロードする行為</li>
            <li>予約後に唐突にキャンセルをする行為、又は、予約とキャンセルを繰り返し行う行為</li>
            <li>運営者に対する暴言、誹謗中傷、嫌がらせ、ハラスメント行為、ストーカー行為、個人的交際の強要（連絡先を確認しようとする行為を含む）その他の迷惑行為</li>
            <li>運営者に対する政治活動（選挙に関する活動を含む）、宗教活動の行為　</li>
            <li>運営者に対し、合理性のない苦情、クレームの申立てを執拗に繰り返す行為</li>
            <li>法令又は公序良俗に反する行為</li>
            <li>その他運営者が不適切と判断する行為</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第10条 (免責事項について) </h2>
          <ol>
            <li>運営者は、利用者が本サービスの利用を通じて得た情報等の正確性、適法性、安全性、特定の目的への適合性等についていかなる保証も行わず、これらに起因して利用者又は第三者に生じた損害について、いかなる責任も負わないものとします。</li>
            <li>運営者は、本サービスへのアクセスにあたっての利用者の機器や通信回線等の障害、エラー、バグの発生等についていかなる責任も負わず、かかる状況等により利用者又は第三者に生じた損害について、いかなる責任も負わないものとします。</li>
            <li>本規約に定めた免責事項は、運営者に故意又は重過失が存する場合には適用しないものとします。また、本規約に定めた免責事項のいずれかの条項の一部が法令により取消し、無効又は差し止めとなった場合であっても、残部は継続して完全に効力を有するものとします。</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第11条（反社会的勢力排除）</h2>
          <ol>
            <li>
              利用者は、自らが暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等社会運動標榜ゴロ又は特殊知能暴力団、その他これらに準ずる者(以下これらを「反社会的勢力」といいます）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
              <ol type="i">
                <li>反社会的勢力が経営を支配していると認められる関係を有すること</li>
                <li>反社会的勢力が経営に実質的に関与していると認められる関係を有すること</li>
                <li>自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること</li>
                <li>反社会的勢力に資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</li>
                <li>役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</li>
              </ol>
            </li>
            <li>利用者は、自ら又は第三者を利用して次の各号の一つにでも該当する行為を行わないことを確約します。
              <ol type="i">
                <li>暴力的な要求行為</li>
                <li>法的な責任を超えた不当な要求行為</li>
                <li>取引に関して、脅迫的な言辞又は暴力を用いる行為</li>
                <li>風説を流布し、偽計若しくは威力を用いて相手方の信用を毀損し、又は業務を妨害する行為</li>
              </ol>
            </li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第12条（本サービス、本規約の変更）</h2>
          <ol>
            <li>運営者は、いつでも本サービスの内容を変更できるものとします。運営者は、かかる変更を行った際は、本サイトを通じて公表するものとし、公表後に利用者が本サービスを利用した場合は、変更に同意したとみなすものとします。</li>
            <li>運営者は、本規約を変更できるものとします。本規約を変更する場合、運営者はあらかじめ利用者に対し、本規約の変更内容及び効力発生時期を通知するものします。ただし、当該変更内容が利用者にとって不利益でない場合は、運営者は、即時に本規約を改定し、事後的に公表する対応に変えることができるものとします。</li>
          </ol>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第13条（個人情報の取扱い）</h2>
          <p>運営者は、利用者の個人情報について、運営者のプライバシーポリシーに従って取り扱うものとします。</p>

          <h2 className="fs-2 fw-bold mb-3 mt-5">第14条 (準拠法及び管轄合意)</h2>
          <p>本規約は、日本法に基づき解釈されるものとします。利用者と運営者の間で生じた一切の紛争については、訴額に応じて、運営者の住所地を管轄する地方裁判所又は簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </p>

          <p class="text-end mb-5">以上</p>

        </section>
      </div>
    </>
  )
}
